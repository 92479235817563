var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.offers, function(offer, index) {
          return _c(
            "div",
            { key: offer.id, staticClass: "col-xs-6 col-sm-4 item-list" },
            [
              _c("OfferItem", {
                attrs: { index: index, offer: offer },
                on: {
                  setOfferIdToDelete: _vm.setOfferIdToDelete,
                  setActiveBrandOffer: _vm.redeemOffer
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "text-center" }, [
        _vm.hasLoadMore
          ? _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: { disabled: _vm.busy },
                on: { click: _vm.loadMore }
              },
              [_vm._v("Load more")]
            )
          : _vm._e()
      ]),
      !_vm.busy && (!_vm.offers || _vm.offers.length == 0)
        ? _c("h3", { staticClass: "text-center mt20" }, [
            _vm._v("\n        No Offer found.\n      ")
          ])
        : _vm._e(),
      _c("OfferPaymentPopup", {
        attrs: { brandOffer: _vm.brandOffer },
        on: { paymentSuccessfull: _vm.setOfferAsRedeemed }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }