























import OfferItem from '@/components/Brand/OfferItem.vue'
import OfferPaymentPopup from '@/components/Brand/OfferPaymentPopup.vue'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
@Component({
  components: {
    OfferItem,
    OfferPaymentPopup
  }
})
export default class Offers extends Vue {
  public query = ''
  public limit = 500
  public offset = 0
  public actionCountry: any = null
  public offers: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public offerIdtoDelete: number = 0
  public brandOffer: any = {
    offer: {
      name: '',
      credits: 0,
      price: 0
    }
  }

  getOffers (clear = true) {
    this.busy = true
    this.$store.dispatch('getBrandOffersList', { query: this.query, limit: this.limit, offset: this.offset, brandId: this.$store.state.userData.brandId }).then((response) => {
      if (clear) this.offers = []
      this.offers.push(...response.response.body.brandOffers)
      this.totalCount = response.response.body.count
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }

  resetOffersList () {
    this.offers = []
    this.offset = 0
    this.getOffers()
  }

  setOfferIdToDelete (offerId:number) {
    this.offerIdtoDelete = offerId
  }

  redeemOffer (offer:any) {
    this.brandOffer = offer
  }
  setOfferAsRedeemed (token:any) {
    this.$store.commit('showLoading')
    let payload = {
      token: token.id,
      brandId: this.$store.state.userData.brandId,
      brandOfferId: this.brandOffer.id,
      offerId: this.brandOffer.offer.id
    }
    this.$http
      .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/offers/redeem`, payload)
      .then((response:any) => {
        if (response.status === 200) {
          this.$router.push('/brand/dashboard')
          this.$store.commit('showMessage', { message: 'Transaction<br /> Successful!' })
        } else {
          this.$notify({ type: 'error', text: 'Internal server error!' })
          this.$store.commit('hideMessage')
        }
      })
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getOffers(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getOffers()
    }, 500)()
  }

  created () {
    this.getOffers()
    this.$store.dispatch('getAppSettingsKeyValuePair')
  }

  refresh () {
    this.offset = 0
    this.getOffers()
  }

  get hasLoadMore (): boolean {
    return this.offers.length < this.totalCount
  }
}
